<template>
    <div class="page">
      <Breadcrumb :title="news.title"/>
      <div class="container">
        <div class="header">
          <h1 class="title">{{ news.title }}</h1>
          <span class="publish_date">{{ news.publishDate | formatDate}}</span>
        </div>
        <div class="divider"></div>
        <div v-html="news.content" class="content"></div>
      </div>
    </div>
  </template>
  
  <script>
  import {query} from "../../api/knowledgeApi"
  import Breadcrumb from '@/components/Breadcrumb';
  
  export default {
    name: "detail",
    components: {
      Breadcrumb,
    },
    data() {
      return {
        news: {},
      }
    },
    created() {
      this.getDetail()
    },
    methods: {
      getDetail() {
        query({id: this.$route.params.id, language: 'en'}).then(res => {
          this.news = res.data.data
        })
      }
    },
    filters: {
        formatDate(date) {
            if (date) {
                return (new Date(date)).Format('yyyy-MM-dd')
            }
            return date;
        }
    }
  }
  </script>
  
  <style scoped lang="less">
  
  /deep/.content{
    .person_pic{
      text-align: center;
      padding: 20px 0px 0px;
      img{
        width: 25%;
      }
    }
    .person_notes{
      text-align: center;
      padding-top: 30px
    }
    padding-bottom: 50px;
  }
  
  .divider{
    width: 100%;
    height: 0px;
    opacity: 1;
    border: 1px solid #F1F1F1;
    margin-bottom: 30px;
  }
  
  /deep/ .txt_line {
    padding-top: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
  }
  
  .header {
    margin-top: 60px;
    .title{
      font-size: 40px;
      font-weight: bold;
      text-align: center;
    }
    .publish_date {
      font-size: 16px;
      display: block;
      padding: 24px 0px;
      color: #666666;
      line-height: 19px;
      text-align: right;
    }
  }
  
  /deep/ .box-card-news__body {
    margin-bottom: 20px;
  }
  
  .about {
    margin: 30px 0px;
  
    .about-line {
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      .about-line__pre {
        width: 25%;
        display: inline-block;
        height: 4px;
        background: #C7000B;
        opacity: 1;
      }
      .about-line__last{
        display: inline-block;
        width: 100%;
        height: 4px;
        background: #E2E2E2;
        opacity: 1;
      }
      .title {
        font-size: 26px;
        color: #3f3f3f;
      }
    }
    .about__holowits{
      font-size: 22px;
      font-weight: bold;
      color: #000000;
      line-height: 26px;
    }
  
    .about__content{
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      padding-top: 30px;
    }
  }
  
  @media (max-width: 991px) {
    .header {
      .title{
        font-size: 24px;
      }
    }
    /deep/.content{
      .person_pic{
        img{
          width: 50%;
        }
      }
    }
    .about {
      .about-line{
        .about-line__pre {
          width: 95%;
        }
      }
      .about__holowits {
        font-size: 18px;
      }
    }
  }
  
  @media (max-width: 750px) {
    /deep/.wscnph {
      width: 100%;
      height: 360px;
      display: block;
      object-fit: contain;
      // object-position: right;
      border-radius: 8px;
    }
  }
  </style>
  